.link.link {
  color: #999999;
  padding: 12px 20px;
  font-size: 14px;
}

.link:global(.active) {
  color: #333333;
  background-color: #ffffff;
}

.link.link:hover {
  color: #333333;
}

.button.button {
  color: #999999;
  text-transform: none;
}

.button.button:hover {
  color: #333333;
  background-color: transparent;
}
